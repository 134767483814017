import React, { useEffect, useState } from 'react';

import { useAPI } from '../../../api';
import { Link, Route, Routes, useParams } from 'react-router-dom';
import PageSection from '../../../components/PageSection';
import SystemBranchInfo from '../SystemBranchInfo';

const SystemBranch = () => {
	const api = useAPI();

	let { branchID } = useParams();

	let [branch, setBranch] = useState(null);

	const loadBranch = () => {
		api.get(`/api/system/branches/${branchID}`)
		.then(json => setBranch(json))
		.catch(err => setBranch(null));
	};

	useEffect(() => {
		loadBranch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<PageSection title={`Branch: ${branch?.name ?? branchID}`} level={2} />

			<PageSection>
				<Link to="/system/branches">&#x25c0; Back to Branches</Link>
			</PageSection>

			<Routes>
				<Route path="/" element={<SystemBranchInfo branch={branch} onChanged={loadBranch} setBranch={setBranch} />} />
			</Routes>
		</div>
	);
}

export default SystemBranch;