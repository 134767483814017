import React, { useEffect, useState } from 'react';
import { useAPI } from '../../../api';
import PageSection from '../../../components/PageSection';
import DataTable from '../../../components/DataTable';

const SystemTasks = () => {
	let [tasks, setTasks] = useState([]);

	let [autoRefreshInterval, setAutoRefreshInterval] = useState(null);

	const api = useAPI();

	const byId = (id) => {
		return document.getElementById(id);
	};

	const loadTasks = () => {
		let filter = '?';

		if (byId('daysFilterEnabled').checked) {
			filter += `days=${byId('daysFilterCount').value}&`;
		}

		if (byId('startFilterEnabled').checked) {
			filter += `start=${byId('startFilter').value}&`;
		}

		if (byId('endFilterEnabled').checked) {
			filter += `end=${byId('endFilter').value}&`;
		}

		if (byId('typeFilterEnabled').checked) {
			filter += `type=${encodeURIComponent(byId('typeFilter').value)}&`;
		}

		if (byId('nameFilterEnabled').checked) {
			filter += `name=${byId('nameFilter').value}&`;
		}

		if (byId('ownerFilterEnabled').checked) {
			filter += `owner=${byId('ownerFilter').value}&`;
		}

		if (byId('branchFilterEnabled').checked) {
			filter += `branch=${byId('branchFilter').value}&`;
		}

		if (filter === '?') filter = '';
		if (filter.endsWith('&')) filter = filter.slice(0, -1);

		api.get('/api/tasks' + filter)
		.then(setTasks);
	};

	const autoRefreshChanged = () => {
		if (byId('autoRefreshEnabled').checked) {
			clearInterval(autoRefreshInterval);

			var lastIntervalID = setInterval(
				() => {
					if (!byId('autoRefreshEnabled')) {
						clearInterval(lastIntervalID);
						setAutoRefreshInterval(null);
						return;
					}

					loadTasks();
				},
				byId('autoRefreshSeconds').value * 1000
			);

			setAutoRefreshInterval(lastIntervalID);
		} else {
			clearInterval(autoRefreshInterval);
			setAutoRefreshInterval(null);
		}
	};

	const filterChanged = () => {
		setTasks([]);
		loadTasks();
	};

	const forceMaintenanceCheck = () => api.post(`/api/tasks/maintenance/run/check`).then(loadTasks);
	const forceMaintenanceExpiry = () => api.post(`/api/tasks/maintenance/run/taskexpiry`).then(loadTasks);

	const disableTask = (id) => api.post(`/api/tasks/${id}/disable`).then(loadTasks);
	const enableTask = (id) => api.post(`/api/tasks/${id}/enable`).then(loadTasks);
	const deleteTask = (id) => api.delete(`/api/tasks/${id}`).then(loadTasks);

	useEffect(() => {
		autoRefreshChanged();
		loadTasks();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<PageSection title="System Tasks" level={2} />

			<PageSection>
				<button onClick={forceMaintenanceCheck}>Force Maint. Check</button>
				<button onClick={forceMaintenanceExpiry}>Force Maint. Expiry</button>
			</PageSection>

			<PageSection title="Task Filters" level={4}>
				<div>
					<input type="checkbox" id="daysFilterEnabled" onChange={filterChanged} /><label htmlFor="daysFilterEnabled">Days</label>&nbsp;
					<input type="number" id="daysFilterCount" defaultValue="7" size="3" onChange={filterChanged} />
				</div>

				<div>
					<input type="checkbox" id="startFilterEnabled" onChange={filterChanged} /><label htmlFor="startFilterEnabled">Start</label>&nbsp;
					<input type="text" id="startFilter" defaultValue={new Date().toISOString().slice(0, 10)} size="8" onChange={filterChanged} />&nbsp;&nbsp;&nbsp;
					<input type="checkbox" id="endFilterEnabled" onChange={filterChanged} /><label htmlFor="endFilterEnabled">End</label>&nbsp;
					<input type="text" id="endFilter" defaultValue={new Date().toISOString().slice(0, 10)} size="8" onChange={filterChanged} />
				</div>

				<div>
					<input type="checkbox" id="typeFilterEnabled" onChange={filterChanged} /><label htmlFor="typeFilterEnabled">Type</label>&nbsp;
					<input type="text" id="typeFilter" defaultValue="" size="15" onChange={filterChanged} />
				</div>

				<div>
					<input type="checkbox" id="nameFilterEnabled" onChange={filterChanged} /><label htmlFor="nameFilterEnabled">Name</label>&nbsp;
					<input type="text" id="nameFilter" defaultValue="" size="15" onChange={filterChanged} />
				</div>

				<div>
					<input type="checkbox" id="ownerFilterEnabled" onChange={filterChanged} /><label htmlFor="ownerFilterEnabled">Owner</label>&nbsp;
					<input type="text" id="ownerFilter" defaultValue="" size="15" onChange={filterChanged} />
				</div>

				<div>
					<input type="checkbox" id="branchFilterEnabled" onChange={filterChanged} /><label htmlFor="branchFilterEnabled">Branch</label>&nbsp;
					<input type="text" id="branchFilter" defaultValue="" size="15" onChange={filterChanged} />
				</div>

				<div>
					<button onClick={loadTasks}>Refresh</button>
				</div>

				<div>
					<input type="checkbox" id="autoRefreshEnabled" defaultChecked={true} onChange={autoRefreshChanged} /> <label htmlFor="autoRefreshEnabled">Autorefresh</label>&nbsp;
					<input type="number" id="autoRefreshSeconds" defaultValue="5" size="5" onChange={autoRefreshChanged} />
				</div>
			</PageSection>

			<PageSection title="Tasks" level={4}>
				<DataTable
					data={tasks}
					columns={[
						{ key: 'id', label: 'ID' },
						{ key: 'type', label: 'Type' },
						{ key: 'name', label: 'Name' },
						{ key: 'enabled', label: 'Enabled', render: (row) => (row.enabled ? 'Yes' : 'No') },
						{ key: 'branch', label: 'Branch', render: (row) => (row.branch ?? '(none)') },
						{ key: 'owner', label: 'Owner', render: (row) => (row.owner ?? '(none)') },
						{ key: 'status', label: 'Status' },
						{ key: 'priority', label: 'Priority' },
						{ key: 'recurring', label: 'Recurring', render: (row) => (row.recurring ? 'Yes' : 'No') },
						{ key: 'progress', label: 'Progress' },
						{ key: 'input', label: 'Input', render: (row) => (JSON.stringify(row.input)) },
						{ key: 'result', label: 'Result', render: (row) => (JSON.stringify(row.result)) },
						{ key: 'lastRun', label: 'Last Run' },
						{ key: 'nextRun', label: 'Next Run' },
						{ key: 'finishedAt', label: 'Finished At' },
						{ key: 'lastError', label: 'Last Error' },
					]}
					tools={[
						task => task.enabled
						? (<button className="link" onClick={() => disableTask(task.id)}>Disable</button>)
						: (<button className="link" onClick={() => enableTask(task.id)}>Enable</button>),
						task => (<button className="link" onClick={() => deleteTask(task.id)}>Delete</button>),
					]}
				/>
			</PageSection>
		</div>
	);
};

export default SystemTasks;