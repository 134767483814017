import React from 'react';

import './DataTable.css';
import Localizer from '../../localizer';

const DataTable = ({ data, columns, tools, tableTools }) => {
	data = data || [];

	columns = columns || 
	(
		data && data.length > 0 && 
		Object.getOwnPropertyNames(data[0]).map(key => ({
			label: key[0].toUpperCase() + key.slice(1),
			key 
		}))
	);

	tools = (tools || []).map(tool => {
		if (typeof tool === 'function') {
			return { render: tool };
		}

		if (tool.action && !tool.render) {
			tool = {
				...tool,
				render: (item) => (<button type="button" className="link" onClick={() => tool.action(item)}>{tool.label}</button>)
			};
		}

		return tool;
	});

	tableTools = (tableTools || []).map(tool => {
		if (typeof tool === 'function') {
			return { render: tool };
		}

		if (tool.action && !tool.render) {
			tool = {
				...tool,
				render: (data) => (<button type="button" className="link" onClick={() => tool.action(data)}>{tool.label}</button>)
			};
		}

		return tool;
	});

	const getValue = (item, column) => {
		if (column.value) {
			if (typeof column.value === 'function') {
				return column.value(item);
			}

			return column.value;
		} else {
			if (column.key && column.key.includes('.')) {
				let parts = column.key.split('.');
				let value = item;

				for (let part of parts) {
					if (value === undefined) {
						break;
					}

					value = value[part];
				}

				return value;
			}
			
			return item[column.key];
		}
	};

	const presentValue = o => {
		if (typeof o === 'object') {
			if (Array.isArray(o)) {
				return `[${o.map(presentValue).join(', ')}]`;
			}
			
			return JSON.stringify(o, null, ' ');
		}

		if (typeof o === 'string') {
			return Localizer.getLocalizedString(String(o));
		}

		return o;
	};

	return (
		<div>
			<table className="dataTable">
				<tbody>
					<tr className="header">
						{
							columns && columns.map((column, columnIndex) => (
								<th key={`c${columnIndex}${column.key}`} className="nowrap">{column.label}</th>
							))
						}

						<th></th>
					</tr>
					{
						data && data.map((item, itemIndex) => (
							<tr key={`i${itemIndex}`}>
								{
									columns && columns.map((column, columnIndex) => (
										<td key={`i${itemIndex}_c${columnIndex}`} className={column.wrapAll ? 'wrapall' : column.noWrap ? 'nowrap' : ''}>
											{
												column.render
												? column.render(item, getValue(item, column))
												: presentValue(getValue(item, column))
											}
										</td>
									))
								}

								<td className="nowrap">
									{
										tools && tools.map((tool, toolIndex) => (
											<React.Fragment key={`t${toolIndex}`}>
												{tool.render(item)}
												{toolIndex < tools.length - 1 && (<span> | </span>)}
											</React.Fragment>
										))
									}
								</td>
							</tr>
						))
					}

					{
						tableTools && tableTools.length > 0 && (
							<tr className="tableTools">
								<td colSpan={(columns?.length ?? 0) + 1}>
									{
										tableTools && tableTools.map((tool, toolIndex) => (
											<React.Fragment key={`tt${toolIndex}`}>
												{tool.render(data)}
												{toolIndex < tableTools.length - 1 && (<span> | </span>)}
											</React.Fragment>
										))
									}
								</td>
							</tr>
						)
					}
				</tbody>
			</table>
		</div>
	);
};

export default DataTable;