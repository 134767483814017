import React from 'react';
import PageSection from '../../../components/PageSection';
import { useAPI } from '../../../api';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PropEditor, { secretProp } from '../../../components/PropEditor';
import RawJsonDisplay from '../../../components/RawJsonDisplay';

import Localizer from '../../../localizer';

const SystemFirewall = ({ onChanged }) => {
	const api = useAPI();
	const navigate = useNavigate();

	const { firewallID } = useParams();

	const [firewallTypes, setFirewallTypes] = React.useState([]);
	const [firewall, setFirewall] = React.useState({
		type: 'fortigate',
		name: '',
		address: '',
		settings: {},
		secrets: {}
	});

	React.useEffect(() => {
		if (firewallID !== 'new') {
			api.get(`/api/system/firewalls/${firewallID}`)
			.then(o => setFirewall(o));
		}

		api.get('/api/system/firewalls/types')
		.then(json => {
			setFirewallTypes(json);
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function testFirewall() {
		api.post(`/api/system/firewalls/test`, {
			id: firewallID !== 'new' ? firewallID : undefined,
			name: firewall.name,
			type: firewall.type,
			address: firewall.address,
			settings: firewall.settings,
			secrets: firewall.secrets
		})
		.then(() => alert('Test successful'));
	};

	function saveFirewall() {
		const firewallData = {
			id: firewall.id,
			name: firewall.name,
			type: firewall.type,
			address: firewall.address,
			settings: firewall.settings,
			secrets: firewall.secrets
		};

		if (firewallID === 'new') {
			api.post(`/api/system/firewalls`, firewallData)
			.then(onChanged)
			.then(() => navigate(`/system/firewalls`));
		} else {
			api.put(`/api/system/firewalls/${firewallID}`, firewallData)
			.then(onChanged)
			.then(() => navigate(`/system/firewalls`));
		}
	}

	return (
		<div>
			<PageSection>
				<Link to="/system/firewalls">&#x25c0; Back to Firewalls</Link>
			</PageSection>

			<PageSection title={firewallID === 'new' ? 'New Firewall' : `Firewall: ${firewall.name}`}>
				<PropEditor
					object={firewall}
					onChange={setFirewall}
					props={[
						{ key: 'id', label: 'ID', disabled: firewallID !== 'new' },
						{ key: 'type', label: 'Type', type: 'select', options: firewallTypes.map(fw => ({ label: `${Localizer.getLocalizedString(fw.name)} (${Localizer.getLocalizedString(fw.vendor)})`, value: fw.id })) },
						{ key: 'name', label: 'Name' },
						{ key: 'address', label: 'Address' },
						secretProp({ context: 'secrets', key: 'accessToken', label: 'Access Token', type: 'password' }),
					]}
				/>

				<button onClick={testFirewall}>Test Firewall</button>
				<button onClick={saveFirewall}>Save Firewall</button>
			</PageSection>

			<PageSection title="Raw Data">
				<RawJsonDisplay data={firewall} />
			</PageSection>

		</div>
	);
};

export default SystemFirewall;