import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { useAPI } from '../../../api';
import PageSection from '../../../components/PageSection';
import PropEditor from '../../../components/PropEditor';
import DataTable from '../../../components/DataTable';
import RawJsonDisplay from '../../../components/RawJsonDisplay';
import Avatar from '../../../components/Avatar';

const SystemUser = () => {
	const api = useAPI();

	const { userID } = useParams();

	const [branches, setBranches] = useState([]);
	const [userInfo, setUserInfo] = useState({});
	const [accessLevels, setAccessLevels] = useState([]);
	const [newAvatar, setNewAvatar] = useState(null);

	const [setAccessGrantState, setSetAccessGrantState] = useState({ branch: '', accessLevel: '', categories: [], groups: [], classes: [] });
	const [changePasswordState, setChangePasswordState] = useState({ password: '', confirmPassword: '' });

	const [accessGrantUserGroups, setAccessGrantUserGroups] = useState([]);
	// const [accessGrantClasses, setAccessGrantClasses] = useState([]);
	const [accessGrantCategories, setAccessGrantCategories] = useState([]);

	const loadUser = () => {
		api.get('/api/system/users/' + userID)
		.then(json => setUserInfo(json));
	};

	const loadBranches = () => {
		api.get('/api/system/branches')
		.then(json => setBranches(json));
	};

	const loadAccessLevels = () => {
		api.get('/api/system/access')
		.then(json => setAccessLevels(json));
	};

	const loadAccessGrantUserGroups = () => {
		if (setAccessGrantState.branch !== '') {
			api.get(`/api/branch/${setAccessGrantState.branch}/firewall/usergroups`)
			.then(json => setAccessGrantUserGroups(json));
		} else {
			setAccessGrantUserGroups([]);
		}
	};

	const loadAccessGrantClasses = () => {
		// if (setAccessGrantState.branch !== '') {
		// 	// api.get(`/api/branch/${setAccessGrantState.branch}/firewall/classes`)
		// 	// .then(json => setAccessGrantClasses(json));
		// 	setAccessGrantClasses([]);
		// } else {
		// 	setAccessGrantClasses([]);
		// }
	};

	const loadAccessGrantCategories = () => {
		if (setAccessGrantState.branch !== '') {
			api.get(`/api/branch/${setAccessGrantState.branch}/firewall/categories`)
			.then(json => setAccessGrantCategories(json));
		} else {
			setAccessGrantCategories([]);
		}
	};


	useEffect(() => {
		loadUser();
		loadBranches();
		loadAccessLevels();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		loadAccessGrantUserGroups();
		loadAccessGrantClasses();
		loadAccessGrantCategories();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setAccessGrantState.branch]);

	function updateVerifyAddAccessGrantState(newState) {
		setSetAccessGrantState(newState);
	};

	function setUserAccessGrant() {
		if (setAccessGrantState.branch === '' || setAccessGrantState.accessLevel === '')
			return alert('Select branch and access level first');
		
		api.put(`/api/system/users/${userID}/access/${setAccessGrantState.branch}`, { 
			accessLevel: setAccessGrantState.accessLevel,
			categories: setAccessGrantState.categories,
			groups: setAccessGrantState.groups,
			classes: setAccessGrantState.classes
		})
		.then(loadUser);
	};

	function deleteAccessGrant(branchID) {
		let accessGrant = userInfo.access.find(ag => ag.branch === branchID);

		let branchName = accessGrant.branch ? branches.find(branch => branch.id === accessGrant.branch)?.name ?? accessGrant.branch : '(none)';
		let accessLevelName = accessLevels.find(accessLevel => accessLevel.id === accessGrant.accessLevel)?.name ?? accessGrant.accessLevel;

		let grantDescription = `${branchName} (${accessLevelName})`;

		if (accessGrant && window.confirm(`Delete access grant ${grantDescription})?`)) {
			api.delete(`/api/system/users/${userID}/access/${branchID}`)
			.then(loadUser);
		}
	}

	function changePassword() {
		if (changePasswordState.password !== changePasswordState.confirmPassword)
			return alert('Passwords do not match');
			
		api.put(`/api/system/users/${userID}/password`, { password: changePasswordState.password })
		.then(json => alert('Changed password'));
	};

	const disable2FA = () => {
		if (window.confirm(`Disable 2FA for ${userInfo.username}?`)) {
			api.post(`/api/system/users/${userInfo.id}/twofactor/disable`)
			.then(json => loadUser());
		}
	};

	const resetAvatar = () => {
		if (window.confirm(`Reset avatar for ${userInfo.username}?`)) {
			api.post(`/api/system/users/${userInfo.id}/avatar/reset`)
			.then(json => {
				reloadAvatar();
				loadUser();
			});
		}
	};

	const reloadAvatar = async () => {
		await Promise.all([
			fetch(`/api/users/${userID}/avatar`, { cache: 'reload' }),
			fetch(`/api/users/${userID}/avatar?size=small`, { cache: 'reload' }),
			fetch(`/api/users/${userID}/avatar?size=medium`, { cache: 'reload' }),
			fetch(`/api/users/${userID}/avatar?size=large`, { cache: 'reload' })
		]);

		document.querySelectorAll('img.avatar').forEach(img => {
			let oldSrc = img.src;
			img.src = null;
			setTimeout(() => img.src = oldSrc, 100);
		});
	};

	return (
		<div>
			<PageSection title={`User: ${userInfo && userInfo.username}`} level={2} />

			<PageSection>
				<Link to="/system/users">&#x25c0; Back to Users</Link>
			</PageSection>

			<PageSection title="Avatar" level={3}>
				<div>
					<div style={{ display: 'inline-block', marginRight: '1em', verticalAlign: 'top' }}>
						<p>Large</p>
						<Avatar src={`/api/users/${userID}/avatar?size=large`} alt="avatar" size="256px" radius="32px" />
					</div>
					<div style={{ display: 'inline-block', marginRight: '1em', verticalAlign: 'top' }}>
						<p>Medium</p>
						<Avatar src={`/api/users/${userID}/avatar?size=medium`} alt="avatar" size="128px" radius="16px" />
					</div>
					<div style={{ display: 'inline-block', marginRight: '1em', verticalAlign: 'top' }}>
						<p>Small</p>
						<Avatar src={`/api/users/${userID}/avatar?size=small`} alt="avatar" size="48px" radius="8px" />
					</div>
				</div>

				<p>
					<input type="file" accept="image/*" onChange={e => {
						let file = e.target.files[0];
						setNewAvatar(file);
					}} />

					{
						newAvatar && 
						(
							<button onClick={() => {
								api.upload(`/api/system/users/${userID}/avatar`, newAvatar)
								.then(() => {
									reloadAvatar();
									setNewAvatar(null);
								});
							}}>Upload Avatar</button>
						)
					}
				</p>

				<p>
					<button onClick={resetAvatar}>Reset Avatar</button>
				</p>
			</PageSection>

			<PageSection title="User Info" level={3}>
				<PropEditor 
					object={userInfo}
					onChange={setUserInfo}
					props={[
						{ label: 'Display Name', key: 'display' },
						{ label: 'Username', key: 'username', disabled: true },
					]}
				/>
			</PageSection>

			<PageSection title="Auth" level={3}>
				<PropEditor
					object={userInfo.auth}
					onChange={(newAuth) => setUserInfo({ ...userInfo, auth: newAuth })}
					props={[
						{ label: 'Strategies', key: 'strategies', type: 'text', disabled: true },
						{ label: 'Last Strategy', key: 'lastStrategy', type: 'text', disabled: true },
					]}
				/>
			</PageSection>

			<PageSection title="Two-Factor Authentication" level={3}>
				{
					userInfo && userInfo.auth?.twoFactor?.enabled ? (
						<div>
							<p>Two-factor authentication is enabled</p>

							<button onClick={disable2FA}>Disable 2FA</button>
						</div>
					) : (
						<div>
							<p>Two-factor authentication is not enabled</p>
						</div>
					)
				}
			</PageSection>

			<PageSection title="Access Grants" level={3}>
				<DataTable
					data={userInfo.access}
					columns={[
						{ label: 'Branch', key: 'branch', render: (access) => access.branch ? branches.find(branch => branch.id === access.branch)?.name ?? access.branch : '(none)' },
						{ label: 'Access Level', key: 'accessLevel', render: (access) => accessLevels.find(accessLevel => accessLevel.id === access.accessLevel)?.name ?? access.accessLevel },
						{ label: 'Categories', key: 'categories', render: (access) => access.categories ? access.categories.length > 0 ? access.categories.join(', ') : '(empty)' : '(null)' },
						{ label: 'Groups', key: 'groups', render: (access) => access.groups ? access.groups.length > 0 ? access.groups.join(', ') : '(empty)' : '(null)' },
						{ label: 'Classes', key: 'classes', render: (access) => access.classes ? access.classes.length > 0 ? access.classes.join(', ') : '(empty)' : '(null)' },
					]}
					tools={[
						{ label: 'Edit', action: (access) => setSetAccessGrantState({ branch: access.branch, accessLevel: access.accessLevel, categories: access.categories, groups: access.groups, classes: access.classes }) },
						{ label: 'Delete', render: (access) => <button className="link" onClick={() => deleteAccessGrant(access.branch)}>Delete</button> }
					]}
				/>
			</PageSection>

			<PageSection title="Raw Data" level={3}>
				<RawJsonDisplay data={userInfo} />
			</PageSection>

			<PageSection title="Grant Access" level={4}>
				<PropEditor
					object={setAccessGrantState}
					onChange={updateVerifyAddAccessGrantState}
					props={
						[
							{
								label: 'Branch',
								key: 'branch',
								type: 'select',
								options: [{ label: '(none)', value: '' }, { label: '(System)', value: 'system' }].concat(branches.map(branch => ({ label: branch.name, value: branch.id })))
							},
							{
								label: 'Access Permission',
								key: 'accessLevel',
								type: 'select',
								options: [{ label: '(none)', value: '' }].concat(accessLevels?.map(accessLevel => ({ label: accessLevel.name, value: accessLevel.id })))
							},
							{
								label: 'Categories',
								key: 'categories',
								type: 'multiselect',
								options: accessGrantCategories.map(category => ({ label: category.name, value: category.id }))
							},
							{
								label: 'Groups',
								key: 'groups',
								type: 'multiselect',
								options: accessGrantUserGroups.map(group => ({ label: group.name, value: group.id }))
							},
							{
								label: 'Classes',
								key: 'classes',
								type: 'text',
								placeholder: 'Class1, Class2, Class3',
								present: (value) => value?.join(', ') ?? '',
								parse: (value) => value.split(',').map(v => v.trim())
							}
						]
					}
					tools={[
						() => <input type="button" value="Grant Access" onClick={setUserAccessGrant} />
					]}
				/>
			</PageSection>

			<PageSection title="Grant Access Raw Data" level={3}>
				<RawJsonDisplay data={setAccessGrantState} />
			</PageSection>

			<PageSection title="Change Password" level={3}>
				<PropEditor
					object={changePasswordState}
					onChange={setChangePasswordState}
					props={[
						{ label: 'Password', key: 'password', type: 'password' },
						{ label: 'Confirm Password', key: 'confirmPassword', type: 'password' }
					]}
					tools={[
						{ label: 'Change Password', render: () => <input type="button" value="Change Password" onClick={changePassword} /> }
					]}
				/>
			</PageSection>
		</div>
	);
};

export default SystemUser;
