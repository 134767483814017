import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { useAPI } from '../../../api';
import SystemUsersList from '../SystemUsersList';
import SystemUserCreateNew from '../SystemUserCreateNew';
import SystemUser from '../SystemUser';

const SystemUsers = () => {
	const api = useAPI();

	const [users, setUsers] = useState([]);

	// // https://stackoverflow.com/a/46240804
	// const [, updateState] = React.useState();
	// const forceUpdate = React.useCallback(() => updateState({}), []);
	
	const loadUsers = () => {
		api.get('/api/system/users')
		.then(json => setUsers(json));
	};

	useEffect(() => {
		loadUsers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Routes>
			<Route path="/" element={<SystemUsersList users={users} onChanged={loadUsers} />} />
			<Route path="/new" element={<SystemUserCreateNew onChanged={loadUsers} />} />
			<Route path="/:userID/*" element={<SystemUser />} />
		</Routes>
	);
};

export default SystemUsers;