import React from 'react';

import PageSection from '../../../components/PageSection';
import DataTable from '../../../components/DataTable';

import { useAPI } from '../../../api';
import { Link, Route, Routes } from 'react-router-dom';
import SystemAuthStrategy from '../SystemAuthStrategy';
import RawJsonDisplay from '../../../components/RawJsonDisplay';

const SystemAuthSettings = ({ onChanged }) => {
	const api = useAPI();

	const [authStrategies, setAuthStrategies] = React.useState([
		{ id: 'local', enabled: false, settings: {}, knownSecrets: [] },
		{ id: 'ldap', enabled: false, settings: {}, knownSecrets: [] },
		{ id: 'saml', enabled: false, settings: {}, knownSecrets: [] },
		{ id: 'google', enabled: false, settings: {}, knownSecrets: [] }
	]);

	const loadAuthStrategies = async () => {
		let local = await api.get('/api/system/auth/local');
		let ldap = await api.get('/api/system/auth/ldap');
		let saml = await api.get('/api/system/auth/saml');
		let google = await api.get('/api/system/auth/google');

		setAuthStrategies([
			local,
			ldap,
			saml,
			google
		]);
	};

	React.useEffect(() => {
		loadAuthStrategies();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function deleteAuthStrategy(strategy) {
		if (window.confirm(`Delete strategy ${strategy}?`)) {
			api.delete(`/api/system/auth/${strategy}`)
			.then(onChanged);
		}
	};

	return (
		<div>
			<PageSection title="Authentication Settings" level={2} />

			<Routes>
				<Route path="/" element={
					<div>
						<PageSection title="Auth Strategies" level={3}>
							<DataTable
								data={authStrategies} 
								columns={[
									{ label: 'Strategy', key: 'id', render: strategy => (<Link to={`/system/auth/${strategy?.id}`}>{strategy?.id}</Link>) },
									{ label: 'Enabled', key: 'enabled', render: strategy => strategy?.enabled ? 'Yes' : 'No' },
									{ 
										label: 'Settings',
										key: 'settings',
										render: (strategy, settings) => (
											<code>
												<div>
													<div>
														{
															settings && Object.keys(settings).length > 0
															? (Object.keys(settings).map(key => (<div key={key}><b>{key}</b>: {String(settings[key]).length > 80 ? `${String(settings[key]).substring(0, 80)}...` : String(settings[key])}</div>)))
															: 'No settings'
														}
													</div>

													<div>
														{
															strategy.knownSecrets && strategy.knownSecrets.length > 0
															? (strategy.knownSecrets.map(key => (<div style={{ fontStyle: 'italic', color: '#80f080' }} key={key}><b>{key}</b>: ********</div>)))
															: 'No secrets'
														}
													</div>
												</div>
											</code>
										)
									}
								]} 
								tools={[
									strategy => (<button className="link" onClick={() => deleteAuthStrategy(strategy?.id)}>Delete</button>)
								]} 
								tableTools={[
									() => (<Link to={`/system/auth/new`}>Add new auth strategy...</Link>)
								]} 
							/>
						</PageSection>

						<PageSection title="Raw Data" level={3}>
							<RawJsonDisplay data={authStrategies} />
						</PageSection>
					</div>
				} />

				<Route path="/:authStrategyID" element={<SystemAuthStrategy onChanged={loadAuthStrategies} />} />
			</Routes>
		</div>
	);
};

export default SystemAuthSettings;
