import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAPI } from '../../../api';

const SystemUserCreateNew = ({ onChanged }) => {
	const api = useAPI();
	const navigate = useNavigate();

	const [user, setUser] = useState({ display: '', username: '' });

	const createUser = () => {
		api.post('/api/system/users', user)
		.then(json => { onChanged(); navigate('/system/users'); });
	};

	return (
		<div>
			<h3>Create User</h3>

			<table>
				<tbody>
					<tr>
						<td>Display Name</td>
						<td><input type="text" value={user.display} onChange={e => { setUser({ ...user, display: e.target.value }); }} /></td>
					</tr>
					<tr>
						<td>Username</td>
						<td><input type="text" value={user.username} onChange={e => { setUser({ ...user, username: e.target.value }); }} /></td>
					</tr>
					<tr>
						<td></td>
						<td><input type="button" value="Create user" onClick={createUser} /></td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default SystemUserCreateNew;