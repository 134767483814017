import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { useAPI } from '../../../api';

import SystemBranchesList from '../SystemBranchesList';
import SystemBranchCreateNew from '../SystemBranchCreateNew';
import SystemBranch from '../SystemBranch';

const SystemBranches = () => {
	const api = useAPI();

	const [branches, setBranches] = useState([]);

	// // https://stackoverflow.com/a/46240804
	// const [, updateState] = React.useState();
	// const forceUpdate = React.useCallback(() => updateState({}), []);
	
	const loadBranches = () => {
		api.get('/api/system/branches')
		.then(json => setBranches(json));
	};

	useEffect(() => {
		loadBranches();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Routes>
			<Route path="/" element={<SystemBranchesList branches={branches} />} />
			<Route path="/new" element={<SystemBranchCreateNew onChanged={loadBranches} />} />
			<Route path="/:branchID/*" element={<SystemBranch />} />
		</Routes>
	);
};

export default SystemBranches;