import React from 'react';

const PageSection = ({ title, level, children }) => {
	level = level ?? 4;
	level = Number(level);

	return (
		<div className="PageSection">
			{
				title && (
					level === 1 ? <h1>{title}</h1> :
					level === 2 ? <h2>{title}</h2> :
					level === 3 ? <h3>{title}</h3> :
					level === 4 ? <h4>{title}</h4> :
					level === 5 ? <h5>{title}</h5> :
					<h6>{title}</h6>
				)
			}

			{children}

			<hr />
		</div>
	);
}

export default PageSection;