import React, { useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';

import { useAuth } from '../../auth/AuthProvider';

import '../../App.css';
import { useAPI } from '../../api';
import PageSection from '../../components/PageSection';
import RawJsonDisplay from '../../components/RawJsonDisplay';
import UserProfileSegment from '../UserProfileSegment';

const SelectBranch = () => {
	const api = useAPI();
	const auth = useAuth();

	let [branches, setBranches] = React.useState(auth?.branches ?? []);

	useEffect(() => {
		api.get('/api/auth/profile')
		.then(json => {
			if (json.user) {
				auth.update(json);
				setBranches(json.branches);
			}
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const autoRedirectSingleBranch = true;

	if (autoRedirectSingleBranch && auth.profile.branches && auth.profile.branches.length === 1)
		return (<Navigate to={`/${auth.profile.branches[0].id}/`} />);
	else {
		return (
			<div className="centeredContainer">
				<div>
					<h3>Choose Your Branch</h3>

					<hr />

					<p>You are logged in as <b>{auth.user}</b></p>

					<p><UserProfileSegment /></p>

					<hr />

					<div className="spacedFormGroup">
						{
							branches && branches.map(branch => (
								<div key={branch.id}>
									<Link to={`/${branch.id}`}>{branch.name} (id={branch.id})</Link>

									{ branch && branch.accessLevel && (<p>Access level: {branch.accessLevel}</p>) }
									{ branch && branch.permissions && (<p>You have branch permissions: [{branch.permissions.join(', ')}]</p>) }
									<hr />
								</div>
							))
						}
					</div>

					{
						auth.profile.branches && auth.profile.branches.find(o => o.id === 'system') ?
						(
							<p>You are a system administrator. <Link to="/system">Manage system</Link></p>
						)
						: null
					}

					<PageSection title="Raw Data" level={4}>
						<RawJsonDisplay data={auth} />
					</PageSection>
				</div>
			</div>
		);
	}
};

export default SelectBranch;