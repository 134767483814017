import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAPI } from '../../../api';
import PageSection from '../../../components/PageSection';
import RawJsonDisplay from '../../../components/RawJsonDisplay';

const SystemAccessLevel = ({ onChanged }) => {
	const api = useAPI();
	const navigate = useNavigate();

	const { accessLevelID } = useParams();

	const [accessLevel, setAccessLevel] = React.useState(
		{
			id: '',
			name: '',
			description: '',
			permissions: []
		}
	);

	const loadAccessLevel = () => {
		api.get(`/api/system/access/${accessLevelID}`)
		.then(json => setAccessLevel(json));
	};

	useEffect(() => {
		if (accessLevelID !== 'new') {
			loadAccessLevel();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const saveAccessLevel = () => {
		let accessLevelData = {
			id: accessLevel.id,
			name: accessLevel.name,
			description: accessLevel.description,
			permissions: accessLevel.permissions
		};

		if (accessLevelID === 'new') {
			api.post(`/api/system/access`, accessLevelData)
			.then(onChanged)
			.then(() => navigate(`/system/access`));
		} else {
			api.put(`/api/system/access/${accessLevelID}`, accessLevelData)
			.then(onChanged)
			.then(() => navigate(`/system/access`));
		}
	};

	return (
		<div>
			<PageSection title={`Access Level: ${accessLevel?.name ?? accessLevelID}`} level={2} />

			<PageSection>
				<Link to={`/system/access`}>&#x25c0; Back to Access Settings</Link>
			</PageSection>

			<PageSection title={accessLevelID === 'new' ? 'Create new access level' : `Edit access level "${accessLevel?.name ?? accessLevelID}"`} level={4}>
				<table>
					<tbody>
						<tr>
							<td>ID</td>
							<td><input type="text" disabled={true} value={accessLevel.id} /></td>
						</tr>
						<tr>
							<td>Name</td>
							<td><input type="text" disabled={accessLevelID !== 'new'} value={accessLevel.name} onChange={e => setAccessLevel({ ...accessLevel, name: e.target.value, id: e.target.value.toLowerCase().replace(/ /g, "-") })} /></td>
						</tr>
						<tr>
							<td>Description</td>
							<td><input type="text" value={accessLevel.description} onChange={e => setAccessLevel({ ...accessLevel, description: e.target.value })} /></td>
						</tr>
						<tr>
							<td>Permissions</td>
							<td>
								<input type="text" value={accessLevel.permissions.join(', ')} onChange={e => setAccessLevel({ ...accessLevel, permissions: e.target.value.split(',').map(s => s.trim()) })} />
							</td>
						</tr>
						<tr>
							<td></td>
							<td><input type="button" value="Save Access Level" onClick={saveAccessLevel} /></td>
						</tr>
					</tbody>
				</table>
			</PageSection>

			<PageSection title="Raw Data" level={4}>
				<RawJsonDisplay data={accessLevel} />
			</PageSection>
		</div>
	);
};

export default SystemAccessLevel;