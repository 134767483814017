import React from 'react';

const Avatar = ({ src, alt, size, radius }) => {
	radius ||= '50%';
	size ||= '24px';
	src ||= 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"%3E%3Cpath d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 22c-5.514 0-10-4.486-10-10S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10zm5-14h-10c-1.654 0-3 1.346-3 3v8c0 1.654 1.346 3 3 3h10c1.654 0 3-1.346 3-3v-8c0-1.654-1.346-3-3-3zm-5 1.5c2.485 0 4.5 2.015 4.5 4.5s-2.015 4.5-4.5 4.5-4.5-2.015-4.5-4.5 2.015-4.5 4.5-4.5z" /%3E%3C/svg%3E';
	
	return src && (
		<img src={src} className="avatar" alt={alt} style={{ lineHeight: size, verticalAlign: 'middle', width: size, height: size, borderRadius: radius }} />
	);
};

export default Avatar;
