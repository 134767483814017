import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAPI } from '../../api';
import { useAuth } from '../../auth/AuthProvider';

const AuthComplete = () => {
	const api = useAPI();
	const auth = useAuth();

	const navigate = useNavigate();

	useEffect(() => {
		api.get('/api/auth/profile')
		.then(json => {
			if (json.user) {
				var loginTarget = localStorage.getItem('afterlogintarget') || '/';
				localStorage.removeItem('afterlogintarget');

				auth.update(json);
				navigate(loginTarget);
			} else {
				auth.clear();
				navigate('/auth/login');
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<p>Completing Authentication...</p>
		</div>
	);
};

export default AuthComplete;