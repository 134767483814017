import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAPI } from '../../../api';
import PageSection from '../../../components/PageSection';
import PropEditor, { secretProp } from '../../../components/PropEditor';
import RawJsonDisplay from '../../../components/RawJsonDisplay';

const SystemAuthStrategy = ({ onChanged }) => {
	const api = useAPI();
	const navigate = useNavigate();

	const { authStrategyID } = useParams();

	const [authStrategy, setAuthStrategy] = useState({ id: authStrategyID === 'new' ? 'local' : authStrategyID, settings: {}});

	const [samlEndpoints, setSamlEndpoints] = useState({
		base: '',
		entityID: '',
		acs: '',
		login: '',
		logout: ''
	});

	const [googleOAuthEndpoints, setGoogleOAuthEndpoints] = useState({
		base: '',
		redirect: ''
	});

	useEffect(() => {
		api.get('/api/auth/endpoints/saml')
		.then(json => setSamlEndpoints(json));

		api.get('/api/auth/endpoints/google')
		.then(json => setGoogleOAuthEndpoints(json));

		if (authStrategyID !== 'new') {
			api.get(`/api/system/auth/${authStrategyID}`)
			.then(o => setAuthStrategy(o));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function saveAuthStrategy() {
		const authData = {
			id: authStrategy.id,
			enabled: authStrategy.enabled,
			settings: authStrategy.settings,
			secrets: authStrategy.secrets
		};

		if (authStrategyID === 'new') {
			api.post(`/api/system/auth`, authData)
			.then(onChanged)
			.then(() => navigate(`/system/auth`));
		} else {
			api.put(`/api/system/auth/${authStrategyID}`, authData)
			.then(onChanged)
			.then(() => navigate(`/system/auth`));
		}
	};

	return (
		<div>
			<PageSection>
				<Link to={`/system/auth`}>&#x25c0; Back to Auth Settings</Link>
			</PageSection>

			<PageSection title={authStrategyID === 'new' ? 'Add new auth strategy' : `Edit auth strategy "${authStrategyID}"`} level={4}>
				<table>
					<tbody>
						<tr>
							<td>Strategy</td>
							<td>
								<select disabled={authStrategyID !== 'new'} value={authStrategy.id} onChange={e => {
									setAuthStrategy({ 
										...authStrategy,
										strategy: e.target.value,
										settings: authStrategy.id !== e.target.value
											? {}
											: authStrategy.settings
									});
								}}>
									<option value="local">Local Auth</option>
									<option value="google">Google</option>
									<option value="ldap">LDAP</option>
									<option value="saml">SAML</option>
								</select>
							</td>
						</tr>
						<tr>
							<td>Enabled</td>
							<td><input type="checkbox" checked={authStrategy.enabled} onChange={e => setAuthStrategy({ ...authStrategy, enabled: e.target.checked })} /></td>
						</tr>
						<tr>
							<td></td>
							<td>
								{
									{
										'local': 
											<div>
												Local auth strategy has no settings.
											</div>,
										'google': 
											<div>
												<PropEditor
													object={authStrategy}
													props={[
														{ type: 'info', label: 'Redirect Settings' },
														{ context: "settings", key: '', label: 'Redirect URL', size: 80, disabled: true, value: googleOAuthEndpoints.redirect },
														{ type: 'info', label: 'OAuth Application Settings' },
														{ context: "settings", key: 'clientID', label: 'Client ID', size: 80, type: 'text' },
														secretProp({ context: "secrets", key: 'clientSecret', label: 'Client Secret', type: 'text' }),
													]}
													onChange={setAuthStrategy}
												/>
											</div>,
										'ldap': 
											<div>
												<PropEditor
													object={authStrategy}
													props={[
														{ context: 'settings', key: 'url', label: 'URL', type: 'text', placeholder: 'ldap://localhost' },
														{ context: 'settings', key: 'port', label: 'Port', type: 'text', placeholder: '389' },
														{ context: 'settings', key: 'secure', label: 'Secure', type: 'checkbox' },
														{ context: 'settings', key: 'bindDN', label: 'Bind DN', type: 'text', placeholder: 'cn=root' },
														secretProp({ context: 'secrets', key: 'bindPassword', label: 'Bind Password', type: 'password' }),
														{ context: 'settings', key: 'searchBaseDN', label: 'Search Base DN', type: 'text', placeholder: 'dc=example,dc=com' },
														{ context: 'settings', key: 'matchAttribute', label: 'Match Attribute', type: 'text', placeholder: 'userPrincipalName' },
														// { key: 'searchFilter', label: 'Search Filter', type: 'text', placeholder: '(userprincipalname={{username}})' },
														// { key: 'searchAttributes', label: 'Search Attributes', type: 'text', placeholder: 'cn,mail' },
														// { key: 'searchScope', label: 'Search Scope', type: 'text', placeholder: 'sub' },
													]}
													onChange={setAuthStrategy}
												/>
											</div>,
										'saml': 
											<div>
												<PropEditor
													object={authStrategy}
													props={[
														{ type: 'info', label: 'Service Provider Configuration' },
														{ context: 'settings', key: '__spcEntityID', label: 'Entity ID', type: 'text', size: 80, disabled: true, value: samlEndpoints.entityID },
														{ context: 'settings', key: '__spcLoginUrl', label: 'Reply URL (ACS URL)', type: 'text', size: 80, disabled: true, value: samlEndpoints.acs },
														{ context: 'settings', key: '__spcLoginUrl', label: 'Login URL', type: 'text', size: 80, disabled: true, value: samlEndpoints.login },
														{ context: 'settings', key: '__spcLogoutUrl', label: 'Logout URL', type: 'text', size: 80, disabled: true, value: samlEndpoints.logout },
														{ type: 'info', label: 'Identity Provider Details' },
														{ context: 'settings', key: 'entityID', label: 'Entity ID (Optional)', type: 'text', size: 80 },
														{ context: 'settings', key: 'loginUrl', label: 'Login URL (ACS URL)', type: 'text', size: 80 },
														{ context: 'settings', key: 'logoutUrl', label: 'Logout URL (SLS URL)', type: 'text', size: 80 },
														secretProp({ context: 'secrets', key: 'cert', label: 'Cert', type: 'file', accept: '.cer', size: 80 }),
														{ context: 'settings', key: 'microsoftGraphApiTenantID', label: 'Microsoft Graph API Tenant ID', type: 'text', size: 80 },
														{ context: 'settings', key: 'microsoftGraphApiClientID', label: 'Microsoft Graph API Client ID', type: 'text', size: 80 },
														secretProp({ context: 'secrets', key: 'microsoftGraphApiClientSecret', label: 'Microsoft Graph API Client Secret', type: 'text', size: 80 }),
													]}
													onChange={setAuthStrategy}
												/>
											</div>,
									}
									[authStrategy.id]
								}
							</td>
						</tr>
						<tr>
							<td></td>
							<td><input type="button" value="Save Auth Strategy" onClick={saveAuthStrategy} /></td>
						</tr>
					</tbody>
				</table>
			</PageSection>

			<PageSection title="Raw Data" level={4}>
				<RawJsonDisplay data={authStrategy} />
			</PageSection>
		</div>
	);
};

export default SystemAuthStrategy;