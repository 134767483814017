import React from 'react';

const RawJsonDisplay = ({ data }) => {
	// Show the raw data with a toggler to show and hide it
	const [showRaw, setShowRaw] = React.useState(false);

	return (
		<div>
			<button className="link" onClick={() => setShowRaw(!showRaw)}>{showRaw ? 'Hide' : 'Show'} Raw Data</button>

			{
				showRaw && (
					<pre style={{ textAlign: 'left', maxWidth: '100%', textWrap: 'wrap', wordWrap: 'normal' }}>
						{JSON.stringify(data, null, 2)}
					</pre>
				)
			}
		</div>
	);
};

export default RawJsonDisplay;