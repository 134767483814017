import React from 'react';
import { useParams } from 'react-router-dom';

import PageSection from '../../../components/PageSection';

import { useAPI } from '../../../api';
import PropEditor from '../../../components/PropEditor';
import RawJsonDisplay from '../../../components/RawJsonDisplay';

const BranchFirewallSettings = ({ branch }) => {
	let api = useAPI();

	let { branchID } = useParams();

	let [branchFirewall, setBranchFirewall] = React.useState({
		firewallID: null,
		firewallGroup: null
	});

	let [branchFirewallSettings, setBranchFirewallSettings] = React.useState({
		policyTemplate: null,
		policyInsertAbove: null
	});

	let [availableFirewalls, setAvailableFirewalls] = React.useState([]);
	let [availableFirewallGroups, setAvailableFirewallGroups] = React.useState([]);
	let [availableFirewallPolicies, setAvailableFirewallPolicies] = React.useState([]);

	const loadBranchFirewall = () => {
		api.get(`/api/branch/${branchID}/firewall`)
		.then(json => setBranchFirewall(json));
	};

	const saveBranchFirewall = () => {
		api.post(`/api/branch/${branchID}/firewall`, branchFirewall)
		.then(json => {
			loadBranchFirewall();
			loadBranchFirewallSettings();
		});
	};

	const saveBranchFirewallForce = () => {
		api.post(`/api/branch/${branchID}/firewall?force=true`, branchFirewall)
		.then(json => {
			loadBranchFirewall();
			loadBranchFirewallSettings();
		});
	};

	const loadBranchFirewallSettings = () => {
		api.get(`/api/branch/${branchID}/firewall/settings`)
		.then(json => setBranchFirewallSettings(json));
	};

	const saveBranchFirewallSettings = () => {
		api.post(`/api/branch/${branchID}/firewall/settings`, branchFirewallSettings)
		.then(json => loadBranchFirewallSettings());
	};

	const loadFirewalls = () => {
		api.get(`/api/branch/${branchID}/admin/firewalls`)
		.then(json => setAvailableFirewalls(json));
	};

	const loadFirewallGroups = () => {
		if (!branchFirewall.firewallID) {
			return setAvailableFirewallGroups([]);
		}

		api.get(`/api/branch/${branchID}/admin/firewalls/${branchFirewall.firewallID}/firewallgroups`)
		.then(json => setAvailableFirewallGroups(json));
	};

	const loadFirewallPolicies = () => {
		if (!branchFirewall.firewallID) {
			return setAvailableFirewallPolicies([]);
		}

		api.get(`/api/branch/${branchID}/admin/firewalls/${branchFirewall.firewallID}/policies`)
		.then(json => setAvailableFirewallPolicies(json));
	}

	React.useEffect(() => {
		loadFirewalls();
		loadBranchFirewall();
		loadBranchFirewallSettings();
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		loadFirewallGroups();
		loadFirewallPolicies();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [branchFirewall]);

	return (
		<div>
			<PageSection level={3} title="Firewall">
				<PropEditor
					object={branchFirewall}
					props={[
						{ label: 'Firewall', key: 'firewallID', type: 'select', options: [{ value: null, label: '(none)' }].concat(availableFirewalls.map(firewall => ({ label: firewall.name, value: firewall.id }))) },
						{ label: 'Firewall Group', key: 'firewallGroup', type: 'select', options: [{ value: null, label: '(none)'}].concat(availableFirewallGroups.map(group => ({ label: group.name, value: group.id }))) },
					]}
					onChange={setBranchFirewall}
				/>

				<button onClick={saveBranchFirewall}>Save Firewall</button>
				<button onClick={saveBranchFirewallForce}>Save Firewall (Force)</button>

			</PageSection>

			<PageSection level={4} title="Raw Data">
				<RawJsonDisplay data={branchFirewall} />
			</PageSection>

			<PageSection level={3} title="Firewall Settings">
				<PropEditor
					object={branchFirewallSettings}
					props={[
						{ label: 'Template Policy', key: 'policyTemplate', type: 'select', options: [{ value: null, label: '(none)'}].concat(availableFirewallPolicies.map(policy => ({ label: policy.name, value: policy.id }))) },
						{ label: 'Insert Above Policy', key: 'policyInsertAbove', type: 'select', options: [{ value: null, label: '(none)'}].concat(availableFirewallPolicies.map(policy => ({ label: policy.name, value: policy.id }))) }
					]}
					onChange={setBranchFirewallSettings}
				/>

				<button onClick={saveBranchFirewallSettings}>Save Firewall Settings</button>
			</PageSection>
			
			<PageSection level={4} title="Raw Data">
				<RawJsonDisplay data={branchFirewallSettings} />
			</PageSection>
		</div>
	);
}

export default BranchFirewallSettings;