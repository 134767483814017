import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useAPI } from '../../../api';

const BranchHome = ({ branch }) => {
	const api = useAPI();
	const { branchID } = useParams();

	let [motd, setMotd] = useState("...");

	useEffect(() => {
		api.get(`/api/branch/${branchID}/settings/motd`)
		.then(json => setMotd(json.motd));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [branchID]);

	const changeMotd = () => {
		var newMotd = prompt("Enter MOTD", motd);

		if (newMotd) {
			api.post(`/api/branch/${branchID}/settings/motd`, { motd: newMotd })
			.then(json => setMotd(newMotd));
		}
	};

	return (
		<div>
			<h3>Branch Home</h3>

			<p>This is the home page for branch "{branch.name}".</p>

			<p>This branch's MOTD is "<span style={{color: '#66ffff'}} onClick={changeMotd}>{motd}</span>"</p>
		</div>
	);
};

export default BranchHome;