import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAPI } from '../api';
import { useAuth } from './AuthProvider';

const RequireAuth = ({children}) => {
	let api = useAPI();
	let auth = useAuth();
	let location = useLocation();

	useEffect(() => {
		api.get('/api/auth/isauthed');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!auth.user) {
		console.warn("User not authenticated, redirecting to login from ", location);

		return <Navigate to="/auth/login" state={{from: location}} replace />
	}

	return children;
};

export default RequireAuth;