import React from 'react';
import PageSection from '../../../components/PageSection';
import DataTable from '../../../components/DataTable';
import { Link } from 'react-router-dom';
import RawJsonDisplay from '../../../components/RawJsonDisplay';

const SystemBranchesList = ({ branches }) => {
	return (
		<div>
			<PageSection title="Branches" level={1}>
				<DataTable 
					data={branches} 
					columns={[
						{ label: 'Name', key: 'name', render: (branch) => <Link to={"/system/branches/" + branch.id}>{branch.name}</Link> },
						{ label: 'ID', key: 'id' }
					]}
					tools={[
						{ label: 'Open Branch', render: (branch) => <Link to={"/" + branch.id}>Open Branch</Link> }
					]}
					tableTools={[
						{ label: 'Create New Branch...', render: () => <Link to="/system/branches/new">Create New Branch...</Link> }
					]}
				/>
			</PageSection>

			<PageSection title="Raw Data" level={2}>
				<RawJsonDisplay data={branches} />
			</PageSection>
		</div>
	);
}

export default SystemBranchesList;
