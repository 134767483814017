import React from 'react';
import PageSection from '../../../components/PageSection';
import { useParams } from 'react-router-dom';
import { useAPI } from '../../../api';
import RawJsonDisplay from '../../../components/RawJsonDisplay';
import PropEditor from '../../../components/PropEditor';

const SystemBranchInfo = ({ branch, onChanged, setBranch }) => {
	const api = useAPI();

	const { branchID } = useParams();

	return (
		<div>
			<PageSection title="Branch Info">
				<PropEditor 
					object={branch}
					onChange={setBranch}
					props={[
						{ label: 'Name', key: 'name' },
						{ label: 'ID', key: 'id', disabled: true }
					]}
					tools={[
						() => (<button onClick={() => api.put(`/api/system/branches/${branchID}`, { name: branch.name }).then(onChanged) }>Save Branch</button>)
					]}
				/>
			</PageSection>

			<PageSection title="Raw">
				<RawJsonDisplay data={branch} />
			</PageSection>
		</div>
	);
};

export default SystemBranchInfo;
