import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useAPI } from '../../../../api';

import PageSection from '../../../../components/PageSection';
import PropEditor from '../../../../components/PropEditor';
import RawJsonDisplay from '../../../../components/RawJsonDisplay';

const OverrideRule = ({ branch, onChanged }) => {
	let api = useAPI();
	let navigate = useNavigate();

	let { branchID, ruleID } = useParams();

	const dateToLocalString = (date) => {
		let d = new Date(date);
		d.setMinutes(d.getMinutes() - d.getTimezoneOffset());
		return d.toISOString().slice(0, 16);
	};

	let [rule, setRule] = useState({
		name: '',
		allowedCategories: [],
		userGroups: [],
		start: dateToLocalString(new Date()),
		end: dateToLocalString(new Date(Number(new Date()) + (30 * 60000))),
		scheduleType: 'next30'
	});

	let [availableCategories, setAvailableCategories] = useState([]);
	let [availableUserGroups, setAvailableUserGroups] = useState([]);

	useEffect(() => {
		if (ruleID !== 'new') {
			api.get(`/api/branch/${branchID}/overrides/rules/${ruleID}`)
			.then(json => setRule({ ...json, scheduleType: 'custom' }));
		}

		api.get(`/api/branch/${branchID}/firewall/categories`)
		.then(json => setAvailableCategories(json));

		api.get(`/api/branch/${branchID}/firewall/usergroups`)
		.then(json => setAvailableUserGroups(json));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const saveOverrideRule = () => {
		let saveRule = { ...rule };

		if (saveRule.scheduleType.startsWith('next')) {
			let minutes = parseInt(saveRule.scheduleType.slice(4));
			saveRule.start = new Date();
			saveRule.end = new Date(Number(new Date()) + (minutes * 60000));
		}

		delete saveRule.scheduleType;

		if (ruleID === 'new') {
			api.post(`/api/branch/${branchID}/overrides/rules`, saveRule)
			.then(onChanged)
			.then(() => navigate(`/${branchID}/overrides`));
		} else {
			api.put(`/api/branch/${branchID}/overrides/rules/${ruleID}`, saveRule)
			.then(onChanged)
			.then(() => navigate(`/${branchID}/overrides`));
		}
	};

	return (
		<div>
			<PageSection>
				<Link to={`/${branchID}/overrides`}>&#x25c0; Back to Overrides</Link>
			</PageSection>

			<PageSection title={ruleID === 'new' ? 'New Override Rule' : `Override Rule '${rule?.name ?? ruleID}'`}>
				<PropEditor
					object={rule}
					props={[
						{ label: 'ID', key: 'id', disabled: true },
						{ label: 'Name', key: 'name' },
						{ label: 'Allowed Categories', key: 'allowedCategories', type: 'multiselect', options: availableCategories.map(c => ({ label: c.name, value: c.id }))},
						{ label: 'User Groups', key: 'userGroups', type: 'multiselect', options: availableUserGroups.map(g => ({ label: g.name, value: g.id }))},
						{ label: 'Schedule', key: 'scheduleType', type: 'select', options: [
							{ label: 'Next 15 Minutes', value: 'next15' },
							{ label: 'Next 30 Minutes', value: 'next30' },
							{ label: 'Next 45 Minutes', value: 'next45' },
							{ label: 'Next 1 Hour', value: 'next60' },
							{ label: 'Custom', value: 'custom' }
						]}
					].concat(rule.scheduleType === 'custom' ? [
						{ label: 'Start', key: 'start', type: 'datetime' },
						{ label: 'End', key: 'end', type: 'datetime' }
					] : [])}
					onChange={setRule}
					tools={[
						{ label: 'Save Override Rule', action: saveOverrideRule }
					]}
				/>
			</PageSection>

			<PageSection title="Raw Data">
				<RawJsonDisplay data={rule} />
			</PageSection>
		</div>
	);
};

export default OverrideRule;
