import React, { useState } from 'react';
import { useAPI } from '../../../api';
import { useNavigate } from 'react-router-dom';

const SystemBranchCreateNew = ({ onChanged }) => {
	const api = useAPI();

	const navigate = useNavigate();

	let [branch, setBranch] = useState({ id: '', name: '' });

	const createBranch = () => {
		api.post(`/api/system/branches`, branch)
		.then(json => { onChanged(); navigate(`/system/branches/${json.id}`); });
	};

	return (
		<div>
			<h2>Create new branch</h2>

			<hr />

			<table>
				<tbody>
					<tr>
						<td>Branch Name</td>
						<td><input type="text" value={branch.name} onChange={e => setBranch({ ...branch, name: e.target.value })} /></td>
					</tr>
					<tr>
						<td>Branch ID</td>
						<td><input type="text" value={branch.id} onChange={e => setBranch({ ...branch, id: e.target.value })} /></td>
					</tr>
					<tr>
						<td></td>
						<td><input type="button" value="Create Branch" onClick={createBranch} /></td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}

export default SystemBranchCreateNew;