import React from 'react';

import PageSection from '../../../components/PageSection';
import { useAPI } from '../../../api';

const SystemBaseUrl = () => {
	let api = useAPI();

	let [baseUrl, setBaseUrl] = React.useState('');

	const loadBaseUrl = () => {
		api.get('/api/system/settings/baseurl')
		.then(json => setBaseUrl(json.baseUrl));
	};

	const saveBaseUrl = () => {
		api.post('/api/system/settings/baseurl', { baseUrl })
		.then(json => { setBaseUrl(json.baseUrl); });
	};

	React.useEffect(() => {
		loadBaseUrl();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<PageSection level={3} title="Base URL">
				<p>
					Base URL for this deployment. This is used in authentication and for generating links in emails and other notifications.
				</p>

				<p>
					Autogenerated base URL for current window: <code>{`${window.location.protocol}//${window.location.host}`}</code>
				</p>

				<p>
					<input type="text" value={baseUrl} onChange={e => setBaseUrl(e.target.value)} />
				</p>

				<p>
					<input type="button" onClick={saveBaseUrl} value="Save" />
				</p>
			</PageSection>
		</div>
	);
};

export default SystemBaseUrl;