import React from 'react';
import PageSection from '../../../components/PageSection';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import { useAPI } from '../../../api';
import DataTable from '../../../components/DataTable';
import SystemFirewall from '../SystemFirewall';

const SystemFirewalls = () => {
	const api = useAPI();
	const navigate = useNavigate();

	const [firewalls, setFirewalls] = React.useState([]);

	const loadFirewalls = () => {
		api.get('/api/system/firewalls')
		.then(json => setFirewalls(json));
	};

	React.useEffect(() => {
		loadFirewalls();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function deleteFirewall(firewallID) {
		if (window.confirm(`Delete firewall ${firewallID}?`)) {
			api.delete(`/api/system/firewalls/${firewallID}`)
			.then(loadFirewalls);
		}
	};

	function deleteFirewallForce(firewallID) {
		if (window.confirm(`Delete firewall ${firewallID} (force)?`)) {
			api.delete(`/api/system/firewalls/${firewallID}?force=true`)
			.then(loadFirewalls);
		}
	};

	return (
		<div>
			<PageSection title="Firewalls" level={2} />

			<Routes>
				<Route path="/" element={
					<div>
						<PageSection title="Firewalls">
							<DataTable
								data={firewalls} 
								columns={[
									{ label: 'Name', key: 'name', render: firewall => (<Link to={`/system/firewalls/${firewall?.id}`}>{firewall?.name}</Link>) },
									{ label: 'ID', key: 'id' },
									{ label: 'Type', key: 'type' },
									{ label: 'URL', key: 'url' },
								]}
								tools={[
									{ label: 'Delete', action: (firewall) => deleteFirewall(firewall.id) },
									{ label: 'Delete (Force)', action: (firewall) => deleteFirewallForce(firewall.id) }
								]}
								tableTools={[
									{ label: 'New Firewall', action: () => navigate('/system/firewalls/new') }
								]}
							/>
						</PageSection>
					</div>
				} />
				<Route path="/:firewallID" element={<SystemFirewall onChanged={loadFirewalls} />} />
			</Routes>
		</div>
	);
};

export default SystemFirewalls;