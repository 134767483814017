import React from 'react';

import { useAPI } from '../../api';

import PageSection from '../../components/PageSection';

import PropEditor from '../../components/PropEditor';

const Setup = ({ onComplete }) => {
	const api = useAPI();

	const [setupStatus, setSetupStatus] = React.useState(null);
	const [adminUser, setAdminUser] = React.useState({ username: '', display: '', password: '', setInBackend: false });
	const [baseUrl, setBaseUrl] = React.useState({ baseUrl: '', setInBackend: false });

	const loadSetupStatus = () => {
		api.get('/api/setup/status')
		.then(json => {
			setSetupStatus(json);
		});
	};

	const loadAdminUser = () => {
		api.get('/api/setup/admin')
		.then(json => {
			setAdminUser({ ...json, setInBackend: !!json.username });
		});
	};

	const loadBaseUrl = () => {
		api.get('/api/setup/baseurl')
		.then(json => {
			setBaseUrl({ ...json, setInBackend: !!json.baseUrl });
		});
	};

	React.useEffect(() => {
		loadSetupStatus();
		loadAdminUser();
		loadBaseUrl();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const setupCompleteReady = setupStatus && setupStatus.sysAdminExists && setupStatus.baseUrlSet;

	return (
		<div>
			<PageSection title="Setup" level={2} />

			<PageSection title="Admin User" level={3}>
				{
					adminUser && adminUser.username && adminUser.setInBackend ? (
						<PropEditor
							object={adminUser}
							onChange={setAdminUser}
							props={[
								{ label: 'User Name', key: 'username', disabled: true },
								{ label: 'Display Name', key: 'display', disabled: true },
							]}
						/>
					) : (
						<PropEditor
							object={adminUser}
							onChange={setAdminUser}
							props={[
								{ label: 'User Name', key: 'username' },
								{ label: 'Display Name', key: 'display' },
								{ label: 'Password', key: 'password', type: 'password' }
							]}
							tools={[
								{ label: 'Create Admin User', render: () => (<input type="button" value="Create Admin User" onClick={() => {
									api.post('/api/setup/admin', { username: adminUser.username, display: adminUser.display, password: adminUser.password })
									.then(() => {
										loadAdminUser();
										loadSetupStatus();
									});
								}} />)} 
							]}
						/>
					)
				}
			</PageSection>

			<PageSection title="Base URL" level={3}>
				{
					baseUrl && baseUrl.baseUrl !== '' && baseUrl.setInBackend ? (
						<PropEditor
							object={baseUrl}
							onChange={setBaseUrl}
							props={[
								{ label: 'Base URL', key: 'baseUrl', disabled: true }
							]}
						/>
					) : (
						<PropEditor
							object={baseUrl}
							onChange={setBaseUrl}
							props={[
								{ label: 'Base URL', key: 'baseUrl', size: 70 }
							]}
							tools={[
								{ label: 'Generate Base URL', render: () => (<input type="button" value="Generate Base URL" onClick={() => {
									setBaseUrl({ ...baseUrl, baseUrl: `${window.location.protocol}//${window.location.host}` });
								}} />)},
								{ label: 'Set Base URL', render: () => (<input type="button" value="Set Base URL" onClick={() => {
									api.post('/api/setup/baseurl', { baseUrl: baseUrl.baseUrl })
									.then(() => {
										loadBaseUrl();
										loadSetupStatus();
									});
								}} />)}
							]}
						/>
					)
				}
			</PageSection>

			<PageSection title="Complete Setup" level={3}>
				{
					setupStatus && (setupCompleteReady ? (
						<div>
							<p>Setup is now ready to be completed! Click the button below to complete setup.</p>
						</div>
					) : (
						<div>
							<p>Setup is not ready to be completed.</p>
						</div>
					))
				}

				<input type="button" value="Complete Setup" disabled={!setupCompleteReady} onClick={() => {
					api.post('/api/setup/complete')
					.then(() => {
						loadSetupStatus();
						onComplete();
					});
				}} />
			</PageSection>
		</div>
	);
}

export default Setup;