import React from 'react';
import { useNavigate } from 'react-router-dom';

let AuthContext = React.createContext(null);

function useAuth() {
	return React.useContext(AuthContext);
}

const AuthProvider = ({children}) => {
	var storedAuth = localStorage.getItem('auth');

	if (storedAuth) {
		try {
			storedAuth = JSON.parse(storedAuth);
		} catch (err) {
			console.error('Could not parse stored auth information:', err);
			storedAuth = null;
		}
	}

	let navigate = useNavigate();

	let [profile, setProfile] = React.useState(storedAuth);

	let update = (updatedAuth) => {
		setProfile(updatedAuth);
		localStorage.setItem('auth', JSON.stringify(updatedAuth));
	};

	let clear = () => {
		localStorage.removeItem('auth');
	};

	let signout = () => {
		navigate('/auth/logout');
	};

	let value = {
		user: profile?.user,
		profile,
		signout,
		update,
		clear
	};

	return (
		<AuthContext.Provider value={value}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;
export { useAuth };