import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '../auth/AuthProvider';

class API {
	constructor (state) {
		this.state = state;
	};

	fetchResponseHandler(res) {
		return new Promise((resolve, reject) => {
			if (res.status === 401) {
				res.json()
				.then(json => {
					console.warn('401 json', json);

					if (json.code === 401001 || json.error === 'invalidauth') {
						this.state.auth.clear();
						this.state.navigate("/auth/login", { state: { from: this.state.location }});
					} else if (json.code === 401003 || json.error === 'reauthrequired') {
						this.state.navigate("/auth/login", { state: { from: this.state.location, reauth: true }});
					} else {
						alert("Response was 401: " + (json.message || json.error));
					}

					reject("Response was 401: " + (json.message || json.error));
				})
				.catch(err => {
					console.error(err);
					alert(err);
					reject(err);
				});
			} else if (res.status === 400) {
				res.json()
				.then(json => {
					console.warn(`${res.status} json`, json);

					alert(json.message ?? json.error);
					reject(json.message ?? json.error);
				})
				.catch(err => {
					console.error(err);
					alert(err);
					reject(err);
				});
			} else if (res.status === 404 || res.status === 500 || res.status === 504) {
				res.json()
				.then(json => {
					console.warn(`${res.status} json`, json);
					alert(json.message ?? json.error);
					reject(json.message ?? json.error);
				})
				.catch(err => {
					console.error(err);
					reject(err);
				});
			} else {
				resolve(res);
			}
		});
	};

	fetchErrorHandler(err) {
		console.error(err);
	};

	fetch(api, options, body) {
		return new Promise((resolve, reject) => {
			fetch(api, {
				...options,
				headers: {
					...(options && options.headers),
					//...this.state.auth.getHeaders()
				},
			}, body)
			.then(res => this.fetchResponseHandler(res))
			.then(res => resolve(res.json()))
			.catch(err => {
				this.fetchErrorHandler(err);
				reject(err);
			});
		});
	};

	get(api, options) {
		return this.fetch(api, { method: 'GET', ...options });
	};

	post(api, data, options) {
		return this.fetch(api, { 
			method: 'POST', 
			headers: { 
				'Content-Type': 'application/json' 
			}, 
			body: JSON.stringify(data), 
			...options 
		});
	};

	put(api, data, options) {
		return this.fetch(api, { 
			method: 'PUT', 
			headers: { 
				'Content-Type': 'application/json' 
			}, 
			body: JSON.stringify(data), 
			...options 
		});
	};

	delete(api, options) {
		return this.fetch(api, { 
			method: 'DELETE', 
			...options 
		});
	};

	upload(api, file, options) {
		return this.fetch(api, { 
			method: 'POST', 
			contentType: 'application/octet-stream',
			body: file,
			...options 
		});
	}
};

const useAPI = () => {
	return new API({
		auth: useAuth(),
		location: useLocation(), 
		navigate: useNavigate()
	});
};

export { useAPI };