import React from 'react';
import PageSection from '../../../components/PageSection';

import { useAuth } from '../../../auth/AuthProvider';
import { useAPI } from '../../../api';

const SystemReset = () => {
	const api = useAPI();
	const auth = useAuth();

	const resetDatabase = () => {
		if (window.confirm("Are you sure you want to reset the database?")) {
			api.post('/api/system/data/delete-everything')
			.then(res => {
				alert(JSON.stringify(res));
				auth.signout();
			});
		}
	};

	return (
		<div>
			<PageSection level={3} title="Reset">
				<p>
					<input type="button" onClick={resetDatabase} value="Reset Database" />
				</p>
			</PageSection>
		</div>
	);
};

export default SystemReset;