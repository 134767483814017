const Localizer = {
	stringData: {
		path: {
			to: {
				'string': 'Localized String'
			}
		},
		firewall: {
			fortinet: {
				vendor: '𝓯𝓸𝓻𝓽𝓲𝓷𝓮𝓽',
				device: {
					fortigate: '𝓯𝓸𝓻𝓽𝓲𝓰𝓪𝓽𝓮',
					fortimanager: '𝓯𝓸𝓻𝓽𝓲𝓶𝓪𝓷𝓪𝓰𝓮𝓻'
				}
			},
			paloalto: {
				vendor: '𝓹𝓪𝓵𝓸𝓪𝓵𝓽𝓸',
				device: {
					paloaltongfw: '𝓹𝓪𝓵𝓸𝓪𝓵𝓽𝓸𝓷𝓰𝓯𝔀',
					panorama: '𝓹𝓪𝓷𝓸𝓻𝓪𝓶𝓪'
				}
			}
		}
	},

	getLocalizedString: (key) => {
		// Use localized string reference or default string from syntax `@s.path.to.string|Default String`
		if (key.startsWith('@s.')) {
			// key is a localized string reference
			let parts = key.split('|');
			let localizedString = Localizer.stringData;

			let path = parts[0].substring(3).split('.');

			for (let i = 0; i < path.length; i++) {
				localizedString = localizedString[path[i]];

				if (!localizedString) {
					break;
				}
			}

			if (localizedString && typeof localizedString === 'string') {
				return localizedString;
			}

			return parts[1] ?? key;
		} else {
			return key;
		}
	}
};

export default Localizer;