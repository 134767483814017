import React from 'react';

import PageSection from '../../../components/PageSection';
import SystemReset from '../SystemReset';
import SystemBaseUrl from '../SystemBaseUrl';

const SystemHome = () => {
	return (
		<div>
			<PageSection level={2} title="Home">
				<p>Welcome to the system application! This is for managing the organizations in this deployment.</p>
			</PageSection>

			<SystemBaseUrl />

			<SystemReset />
		</div>
	);
};

export default SystemHome;