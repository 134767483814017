import React, { useState } from 'react';
import { useAPI } from '../../../api';
import { useNavigate } from 'react-router-dom';

import PropEditor from '../../../components/PropEditor';

const BranchUserCreateNew = ({branch, onChanged}) => {
	const api = useAPI();
	const navigate = useNavigate();

	let [user, setUser] = useState({ display: '', username: '', accessLevel: null });
	let [accessLevels, setAccessLevels] = useState([]);

	const createUser = () => {
		let newUser = {
			display: user.display,
			username: user.username,
			access: [{
				branch: branch.id,
				accessLevel: user.accessLevel
			}]
		};

		api.post(`/api/branch/${branch.id}/admin/users`, newUser)
		.then(json => { onChanged(); navigate(`/${branch.id}/users`); });
	};

	React.useEffect(() => {
		api.get(`/api/branch/${branch.id}/admin/access`)
		.then(json => setAccessLevels(json));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<h3>Create User</h3>

			<PropEditor 
				object={user}
				onChange={setUser}
				props={[
					{ key: 'display', label: 'Display Name' },
					{ key: 'username', label: 'Username' },
					{ key: 'accessLevel', label: 'Access Level', type: 'select', options: [{ value: null, label: '(none)' }].concat(accessLevels.map(al => ({ value: al.id, label: al.name }))) }
				]}
				tools={[
					{ label: 'Create user', action: createUser }
				]}
			/>
		</div>
	);
};

export default BranchUserCreateNew;