import React from 'react';

import PageSection from '../../components/PageSection';
import { useAPI } from '../../api';
import { useNavigate } from 'react-router-dom';

const AuthTwoFactor = () => {
	const api = useAPI();
	const navigate = useNavigate();

	const [code, setCode] = React.useState('');
	const [recoveryCode, setRecoveryCode] = React.useState('');
	const [recoveryMode, setRecoveryMode] = React.useState(false);
	const [error, setError] = React.useState(null);

	const verifyCode = (code) => {
		api.post('/api/auth/twofactor/verify', { code })
		.then(() => navigate('/auth/complete'))
		.catch(err => setError(`Failed to verify code: ${String(err)}`));
	};

	const verifyRecoveryCode = (recoveryCode) => {
		api.post('/api/auth/twofactor/recovery', { recoveryCode })
		.then(() => navigate('/auth/complete'))
		.catch(err => setError(`Failed to verify recovery code: ${String(err)}`));
	};

	return (
		<div className="centeredContainer">
			<PageSection title="Two Factor Authentication" level={3}>
				<p>Two Factor Authentication is required for this account.</p>

				<div>
					{
						recoveryMode ? (
							<div>
								<p>Please enter a recovery code.</p>

								<form onSubmit={e => { e.preventDefault(); verifyRecoveryCode(recoveryCode); }}>
									<p>
										<input type="text" size="17" autoFocus={true} style={{ fontSize: '150%', fontFamily: 'monospace' }} value={recoveryCode} onChange={e => setRecoveryCode(e.target.value)} />
									</p>

									<p>
										<button type="submit">Verify Recovery Code</button>
									</p>
								</form>

								<div className="spacedFormGroup">
									<button className="link" onClick={() => setRecoveryMode(false)}>Use Authenticator Code</button>
								</div>
							</div>
						) : (
							<div>
								<p>Please enter the code from your authenticator app.</p>

								<form onSubmit={e => { e.preventDefault(); verifyCode(code); }}>
									<p>
										<input type="text" size="6" autoFocus={true} style={{ fontSize: '250%', fontFamily: 'monospace' }} value={code} onChange={e => setCode(e.target.value)} />
									</p>

									<p>
										<button type="submit">Verify Code</button>
									</p>
								</form>

								<div className="spacedFormGroup">
									<button className="link" onClick={() => setRecoveryMode(true)}>Use Recovery Code</button>
								</div>
							</div>
						)
					}

					{
						error && (
							<div className="spacedFormGroup">
								<p style={{ color: 'red' }}>{error}</p>
							</div>
						)
					}
				</div>
			</PageSection>
		</div>
	);
};

export default AuthTwoFactor;