import React from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { useAuth } from './AuthProvider';

const RequireAccess = ({ branch, children }) => {
	let auth = useAuth();
	let location = useLocation();

	const { branchID } = useParams();

	if (!branch) branch = branchID;

	if (!auth.user) {
		console.warn("User not authenticated, redirecting to login from ", location);

		return <Navigate to="/auth/login" state={{from: location}} replace />
	} else if (auth.profile.branches) {
		let branchAccess = auth.profile.branches.find(o => o.id === branch);

		if (!branchAccess) {
			console.warn(`User authenticated, but does not have access to branch '${branch}', redirecting to branch selector`);
			return <Navigate to="/auth/selectbranch" replace />
		}
	}

	return children;
};

export default RequireAccess;