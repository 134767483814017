import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth} from '../../auth/AuthProvider';

const Logout = () => {
	const navigate = useNavigate();
	const auth = useAuth();

	const logoutFormRef = useRef(null);

	React.useEffect(() => {
		auth.clear();
		logoutFormRef.current.submit();
	}, [navigate, auth]);

	return (
		<div><h2>Logging out...</h2><form action="/api/auth/logout" method="post" ref={logoutFormRef}></form></div>
	);
};

export default Logout;