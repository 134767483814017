import React from 'react';
import { Link, useParams } from 'react-router-dom';

import { useAuth } from '../../auth/AuthProvider';
import Avatar from '../../components/Avatar';

const UserProfileSegment = () => {
	const auth = useAuth();

	let { branchID } = useParams();

	let isSysadmin = auth.profile.branches.find(branch => branch.id === 'system');

	if (!branchID) {
		if (isSysadmin)
			branchID = 'system';
		else
			branchID = auth.profile.branches[0].id;
	}

	return (
		<React.Fragment>
			[User:
			{<React.Fragment>&nbsp;<Avatar src={`/api/users/${auth.profile.id}/avatar?size=small`} size="20px" /></React.Fragment>}
			&nbsp;
			<Link to={`/${branchID}/profile`}>
				<span style={{color: '#ffff66'}}>{auth.profile?.name} <span style={{ fontSize: '75%' }}>({auth.user})</span></span>
			</Link>
			&nbsp;
			(<Link to="/auth/logout">
					Logout
			</Link>)]
		</React.Fragment>
	);
};

export default UserProfileSegment;