import React, { useState } from 'react';
import { useAPI } from '../../../api';
import { useParams } from 'react-router-dom';

const BranchTaskTests = () => {
	const api = useAPI();
	const { branchID } = useParams();

	const monitorTask = (id, setProgress, done) => {
		let timer = setInterval(
			() => {
				api.get(`/api/tasks/${id}`)
				.then(json => {
					console.log(`Task ${id} status`, json);
					setProgress(json.progress);
					if (json.status === 'completed') {
						if (done) done(json.result);
						clearInterval(timer);
					} else if (json.status === 'failed') {
						if (done) done(json.lastError);
						clearInterval(timer);
					} else {
						done(json.status);
					}
				});
			},
			1000
		);
	};

	const useTestTask = (apiPath, initialValue, taskInputWrapFunction) => {
		let [taskInput, setTaskInput] = useState(initialValue);
		let [taskResult, setTaskResult] = useState(null);
		let [taskProgress, setTaskProgress] = useState(0);

		const startTask = () => {
			api.post(apiPath, taskInputWrapFunction(taskInput))
			.then(json => monitorTask(json.id, setTaskProgress, setTaskResult));
		};

		const createUI = () => {
			return (
				<p>
					<input type="text" value={taskInput} onChange={e => setTaskInput(e.target.value)} />&nbsp;&nbsp;
					<button onClick={startTask}>Start Task</button>&nbsp;&nbsp;
					<span>Progress: {taskProgress * 100}%</span>&nbsp;&nbsp;
					<span>Result: {taskResult}</span>
				</p>
			);
		};

		return {
			initialValue,
			apiPath,
			startTask,
			createUI,
			taskInputWrapFunction,
			input: taskInput,
			setInput: setTaskInput,
			result: taskResult,
			setResult: setTaskResult,
			progress: taskProgress,
			setProgress: setTaskProgress
		};
	};

	let systemImmTestTask = useTestTask('/api/experimental/testtask/run', 42, input => ({ x: input, y: 3 }));
	let systemImmTaskExtProc = useTestTask('/api/experimental/testtaskextproc/run', 100, input => ({ x: input, y: 3 }));
	let systemOnceTestTask = useTestTask('/api/experimental/testtask/schedule', 150, input => ({ x: input, y: 3 }));

	let orgImmTestTask = useTestTask(`/api/branch/${branchID}/experimental/testtask/run`, 123, input => ({ x: input, y: 5 }));
	let orgImmTaskExtProc = useTestTask(`/api/branch/${branchID}/experimental/testtaskextproc/run`, 300, input => ({ x: input, y: 100 }));
	let orgOnceTestTask = useTestTask(`/api/branch/${branchID}/experimental/testtask/schedule`, 500, input => ({ x: input, y: 123 }));
	let orgRepeatTestTask = useTestTask(`/api/branch/${branchID}/experimental/testtask/repeat`, 1234, input => ({ x: input, y: 4567 }));

	return (
		<div>
			<h3>Test Tasks</h3>

			<hr />

			<h4>System-wide test tasks</h4>

			<div><h5>Immediate Test Task</h5> {systemImmTestTask.createUI()}</div>
			<div><h5>Immediate Test Task ExtProc</h5> {systemImmTaskExtProc.createUI()}</div>
			<div><h5>Schedule (Once) Test Task</h5> {systemOnceTestTask.createUI()}</div>

			<hr />

			<h4>Org-specific test tasks</h4>

			<div><h5>Immediate Test Task</h5> {orgImmTestTask.createUI()}</div>
			<div><h5>Immediate Test Task ExtProc</h5> {orgImmTaskExtProc.createUI()}</div>
			<div><h5>Schedule (Once) Test Task</h5> {orgOnceTestTask.createUI()}</div>
			<div><h5>Schedule (Repeat) Test Task</h5> {orgRepeatTestTask.createUI()}</div>

		</div>
	);
};

export default BranchTaskTests;