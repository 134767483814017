import React from 'react';
import PageSection from '../../../components/PageSection';
import DataTable from '../../../components/DataTable';

import { useAPI } from '../../../api';
import { Link, Route, Routes } from 'react-router-dom';
import SystemAccessLevel from '../SystemAccessLevel';

const SystemAccessSettings = ({ onChanged }) => {
	const api = useAPI();

	const [accessLevels, setAccessLevels] = React.useState([]);

	const loadAccessLevels = () => {
		api.get('/api/system/access')
		.then(json => setAccessLevels(json));
	}

	React.useEffect(() => {
		loadAccessLevels();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function deleteAccessLevel(accessLevelID) {
		if (window.confirm(`Delete access level ${accessLevelID}?`)) {
			api.delete(`/api/system/access/${accessLevelID}`)
			.then(onChanged);
		}
	};

	return (
		<div>
			<PageSection title="Access Settings" level={2} />

			<Routes>
				<Route path="/" element={
					<div>
						<PageSection title="Access Levels">
							<DataTable
								data={accessLevels}
								columns={[
									{ label: 'Access Level', noWrap: true, render: accessLevel => (<Link to={`/system/access/${accessLevel?.id}`}>{accessLevel?.name}</Link>) },
									{ label: 'ID', noWrap: true, key: 'id' },
									{ label: 'Description', key: 'description' },
									{ label: 'Permissions', key: 'permissions', render: permissions => permissions.permissions?.join(', ') }
								]}
								tools={[
									accessLevel => (<button className="link" onClick={() => deleteAccessLevel(accessLevel?.id)}>Delete</button>)
								]}
								tableTools={[
									() => (<Link to={`/system/access/new`}>Create new access level...</Link>)
								]}
							/>
						</PageSection>
					</div>
				} />

				<Route path="/:accessLevelID" element={<SystemAccessLevel onChanged={loadAccessLevels} />} />
			</Routes>
		</div>
	);
}

export default SystemAccessSettings;