import React from 'react';
import PageSection from '../../../components/PageSection';
import { useAPI } from '../../../api';
import DataTable from '../../../components/DataTable';
import { Link, Route, Routes } from 'react-router-dom';
import BranchUserCreateNew from '../BranchUserCreateNew';
import BranchUser from '../BranchUser';
import Avatar from '../../../components/Avatar';

const BranchUsers = ({branch}) => {
	const api = useAPI();

	let [branchUsers, setBranchUsers] = React.useState([]);

	const loadBranchUsers = () => {
		api.get(`/api/branch/${branch.id}/admin/users`)
		.then(json => setBranchUsers(json));
	};

	const deleteBranchUser = (user) => {
		if (window.confirm(`Delete user ${user.display}?`)) {
			api.delete(`/api/branch/${branch.id}/admin/users/${user.id}`)
			.then(loadBranchUsers);
		}
	};

	React.useEffect(() => {
		loadBranchUsers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Routes>
			<Route path="/" element={
				<div>
				<PageSection level={3} title="Branch Users">
					<p>Branch users for {branch.name}</p>

					<DataTable
						data={branchUsers}
						columns={[
							{ label: '', key: 'avatar', render: (user) => <Avatar src={user.avatar} alt="" size="24px" /> },
							{ label: 'Name', key: 'display', render: (user) => <Link to={`/${branch.id}/users/${user.id}`}>{user.display}</Link> },
							{ label: 'ID', key: 'id' },
							{ label: 'Username', key: 'username' },
							{ label: 'Status', key: 'status' },
						]}
						tools={[
							{ label: 'Delete', action: deleteBranchUser }
						]}
						tableTools={[
							{ label: 'New User', render: () => <Link to={`/${branch.id}/users/new`}>New User</Link> }
						]}
					/>
				</PageSection>
			</div>
			} />
			<Route path="/new" element={<BranchUserCreateNew branch={branch} onChanged={loadBranchUsers} />} />
			<Route path="/:userID/*" element={<BranchUser branch={branch} />} />
		</Routes>
	);
};

export default BranchUsers;