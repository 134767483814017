import React from 'react';

import PageSection from '../../../components/PageSection';
import BranchFirewallSettings from '../BranchFirewallSettings';

const BranchSettings = ({ branch }) => {
	return (
		<div>
			<PageSection level={2} title="Settings">
				<p>Settings for branch '{branch.name}'</p>
			</PageSection>

			<BranchFirewallSettings branch={branch} />
		</div>
	);
};

export default BranchSettings;