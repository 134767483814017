import React from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import PageSection from '../../../components/PageSection';
import { useAPI } from '../../../api';
import DataTable from '../../../components/DataTable';
import OverrideRule from './OverrideRule';
import RawJsonDisplay from '../../../components/RawJsonDisplay';

const Overrides = ({ branch }) => {
	let api = useAPI();

	let [overrideRules, setOverrideRules] = React.useState([]);

	const loadOverrideRules = () => {
		api.get(`/api/branch/${branch.id}/overrides/rules`)
		.then(json => setOverrideRules(json));
	};

	React.useEffect(() => {
		loadOverrideRules();

		const interval = setInterval(loadOverrideRules, 5000);

		return () => {
			clearInterval(interval);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const deleteRule = (rule) => {
		if (window.confirm(`Delete rule ${rule.name}?`)) {
			api.delete(`/api/branch/${branch.id}/overrides/rules/${rule.id}`)
			.then(loadOverrideRules);
		}
	};

	return (
		<div>
			<Routes>
				<Route path="/" element={
					<div>
						<PageSection title="Overrides" level={2}>
							<p>Overrides for branch {branch.name}</p>

							<DataTable
								data={overrideRules}
								columns={[
									{ label: 'Name', key: 'name', render: (rule) => <Link to={`/${branch.id}/overrides/${rule.id}`}>{rule.name}</Link> },
									{ label: 'ID', key: 'id' },
									{ label: 'Status', key: 'status' },
									{ label: 'Message', value: (rule) => {
										switch (rule.status) {
											case 'scheduled':
												return '@s.overrides.rules.status.scheduled.message|Rule scheduled for future application on your firewall.';
											case 'pending':
												return '@s.overrides.rules.status.pending.message|Currently applying the rule to your firewall. This may take a few moments.';
											case 'active':
												return '@s.overrides.rules.status.active.message|The rule is now active and applied to your firewall.';
											case 'expired':
												return '@s.overrides.rules.status.active.message|This rule has expired and will no longer be applied to your firewall. You may delete the rule or edit to reapply';
											case 'failed':
												return `Failed to apply the rule: ${rule.error ?? 'Unknown Error'}. Please retry or contact your administrator.`;
											default:
												return 'Unknown';
										}
									}},
									{ label: 'Categories', wrapAll: true, key: 'allowedCategories' },
									{ label: 'User Groups', wrapAll: true, key: 'userGroups' },
									{ label: 'Owner', key: 'owner' },
									{ label: 'Start', key: 'start' },
									{ label: 'End', key: 'end' }
								]}
								tools={[
									{ label: 'Delete', action: (rule) => deleteRule(rule) }
								]}
								tableTools={[
									() => <Link to={`/${branch.id}/overrides/new`}>New Rule</Link>
								]}
							/>
						</PageSection>

						<PageSection title="Raw Data" level={2}>
							<RawJsonDisplay data={overrideRules} />
						</PageSection>
					</div>
				} />
				<Route path="/:ruleID" element={<OverrideRule onChanged={loadOverrideRules} />} />
			</Routes>
		
		</div>
	);
};

export default Overrides;